/* eslint-disable no-multi-assign, func-names */ // ok in reducers
import * as types from 'constants/action_types'
import * as pages from 'constants/pages'
import { deepCopy, hasProp } from 'util/objects'
import { DELETE_DRAFT } from 'ducks/drafts2/constants'

const defaultState = {}
const reducers = {}

reducers[types.ATTACHMENT_UPLOAD_STARTED] = function(state, action) {
  const {
    ticketId,
    file,
    key,
    forNote,
    expiringUrl,
    uploadRequest,
  } = action.data
  const byTicketId = Object.assign({}, state.byTicketId)
  const attachments = Object.assign({}, byTicketId[ticketId])

  attachments[file.name] = {
    progress: 0,
    file: action.data.file,
    key,
    forNote: !!forNote,
    expiringUrl,
    isUploading: true,
    uploadRequest,
  }
  byTicketId[ticketId] = attachments

  return Object.assign({}, state, {
    byTicketId,
  })
}

reducers[types.ATTACHMENT_UPLOAD_PROGRESSED] = function(state, action) {
  const { ticketId, file } = action.data
  const byTicketId = Object.assign({}, state.byTicketId)
  const oldAttachment = Object.assign({}, byTicketId[ticketId][file.name])
  const newAttachment = Object.assign({}, oldAttachment, {
    progress: action.data.percentage,
  })
  byTicketId[ticketId][file.name] = newAttachment

  return Object.assign({}, state, {
    byTicketId,
  })
}

reducers[types.ATTACHMENT_UPLOAD_FAILED] = function(state, action) {
  const { ticketId, file, error, status } = action.data
  const attachments = deepCopy(state.byTicketId[ticketId])

  if (error?.type === 'abort') {
    delete attachments[file.name]
  } else {
    attachments[file.name] = {
      ...attachments[file.name],
      progress: null,
      isUploading: false,
      error,
      status,
      uploadRequest: null,
    }
  }

  return {
    ...state,
    byTicketId: {
      ...state.byTicketId,
      [ticketId]: attachments,
    },
  }
}

reducers[types.ATTACHMENT_UPLOAD_FINISHED] = function(state, action) {
  const { ticketId, file } = action.data
  const byTicketId = deepCopy(state.byTicketId)

  const attachments = byTicketId[ticketId]

  if (attachments) {
    delete attachments[file.name]
  }

  return {
    ...state,
    byTicketId,
  }
}

function deleteForTicket(state, ticketId) {
  if (!ticketId) return state
  const byTicketId = deepCopy(state.byTicketId)

  delete byTicketId[ticketId]

  return {
    ...state,
    byTicketId,
  }
}
// clears out any (failed) attachments that still sit in this data structure,
// when the user explicitly deletes the draft
reducers[DELETE_DRAFT] = (state, action) => {
  const { ticketId } = action.payload
  return deleteForTicket(state, ticketId)
}

// clears out any (failed) attachments that still sit in this data structure,
// when you switch to a new reply/note page.
reducers[pages.TICKET_PAGE] = reducers[pages.TICKET_COMMENT_PAGE] = (
  state,
  action
) => {
  const { id: ticketId } = action.payload
  return deleteForTicket(state, ticketId)
}

reducers[pages.LOG_CONVERSATION_PAGE] = reducers[
  pages.NEW_CONVERSATION_PAGE
] = state => {
  return deleteForTicket(state, 'new')
}

reducers[types.DELETE_ATTACHMENT] = (state, action) => {
  const {
    ticketId,
    file: { filename },
  } = action.data

  if (!ticketId || !filename) return state

  const byTicketId = deepCopy(state.byTicketId)

  if (!hasProp(byTicketId, ticketId)) return state

  delete byTicketId[ticketId][filename]

  return {
    ...state,
    byTicketId,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
