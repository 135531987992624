import { selectGroups } from 'selectors/app/base'
import { selectAgents, selectCurrentAgent } from 'selectors/agents/base'
import { selectMailboxes } from 'ducks/mailboxes/selectors/selectMailboxes'
import { getAgentUsername } from 'util/agents'
import { emptyObj, values } from 'util/objects'
import { constructSearchQueryObject, constructSearchQueryId } from 'util/search'
import createCachedSelector from 're-reselect'
import { selectCurrentTagsById } from 'ducks/tags/selectors'
import { selectCurrentFoldersById } from 'ducks/folders/selectors/folders'
import { buildIdFromAny } from 'util/globalId'
import { createBasicSelector } from 'util/redux'

const selectTicketSearchOperatorValueMapByProperty = createCachedSelector(
  selectCurrentAgent,
  selectAgents,
  selectGroups,
  selectMailboxes,
  selectCurrentTagsById,
  selectCurrentFoldersById,
  (_state, property) => property,
  (_state, _property, options) => options || {},
  (
    currentAgent,
    agents,
    groups,
    mailboxes,
    tagsById,
    foldersById,
    property,
    { disableLabels = false }
  ) => {
    const agentMap =
      agents &&
      Object.assign(
        agents.reduce((newAgents, agent) => {
          const name = getAgentUsername(agent)
          // eslint-disable-next-line no-param-reassign
          newAgents[name] = property
            ? buildIdFromAny('Agent', agent[property])
            : agent
          return newAgents
        }, {}),
        currentAgent && {
          me: property
            ? buildIdFromAny('Agent', currentAgent[property])
            : currentAgent,
        }
      )
    return {
      draft: agentMap,
      assignee: agentMap,
      mentions: agentMap,
      assigned_group:
        groups &&
        groups.reduce((newGroups, group) => {
          // eslint-disable-next-line no-param-reassign
          newGroups[group.name] = property
            ? buildIdFromAny('Team', group[property])
            : group
          return newGroups
        }, {}),
      mailbox:
        mailboxes &&
        mailboxes.reduce((newMailboxes, mailbox) => {
          // eslint-disable-next-line no-param-reassign
          newMailboxes[mailbox.name] = property
            ? buildIdFromAny('Channel', mailbox[property])
            : mailbox
          return newMailboxes
        }, {}),
      tagId:
        !disableLabels &&
        tagsById &&
        // perf: we intentionally do the values mapping here and not in the 'base' folders selector.
        values(tagsById).reduce((newLabels, label) => {
          // eslint-disable-next-line no-param-reassign
          newLabels[label.name] = property ? label[property] : label
          return newLabels
        }, {}),
      tag:
        !disableLabels &&
        tagsById &&
        // perf: we intentionally do the values mapping here and not in the 'base' folders selector.
        values(tagsById).reduce((newLabels, label) => {
          // eslint-disable-next-line no-param-reassign
          newLabels[label.name] = property ? label[property] : label
          return newLabels
        }, {}),
      folder:
        foldersById &&
        // perf: we intentionally do the values mapping here and not in the 'base' folders selector.
        values(foldersById).reduce((newFolders, folder) => {
          // eslint-disable-next-line no-param-reassign
          newFolders[folder.name] = property ? folder[property] : folder
          return newFolders
        }, {}),
    }
  }
)(
  (_state, property, options = {}) =>
    `${property || 'unknown'}|${Object.keys(options)
      .map(k => `${k}:${options[k]}`)
      .join('|')}`
)

export const selectTicketSearchOperatorValueMap = (
  state,
  options = emptyObj
) => {
  return selectTicketSearchOperatorValueMapByProperty(state, 'id', options)
}

export const selectTicketSearchOperatorFullValueMap = state => {
  return selectTicketSearchOperatorValueMapByProperty(state, null)
}

// Committed search and uncommitted search is basically the distinction between
// the search that the user entered and the search that we've amended with a
// suggestion. As the user keys or mouses through suggestions we ammend the
// search input, this is the uncommitted search query.
// The committed search query represents what the user has committed to ie if
// you select an autosuggest item or type something manually it goes into the
// committed query.
export function selectCurrentCommittedTicketSearchQueryString(state) {
  const { committedSearchQueryString } = state.search
  if (committedSearchQueryString || committedSearchQueryString === '') {
    return committedSearchQueryString
  }
  return null
}

export function selectCurrentTicketSearchSelectionStart(state) {
  const { selectionStart } = state.search
  if (selectionStart || selectionStart === 0) {
    return selectionStart
  }
  return null
}

export const selectCurrentCommittedTicketSearchQueryCurrentPart = state =>
  state.search.currentPart

export const selectIsListSearchBoxFocused = state =>
  state.search.listSearchBoxFocused

export const selectTicketSearchQueryIdByQueryString = createCachedSelector(
  (_state, queryString) => queryString,
  (state, _queryString, options = emptyObj) =>
    selectTicketSearchOperatorValueMap(state, options),
  (queryString, valueMap) => {
    return constructSearchQueryId(
      constructSearchQueryObject(queryString, valueMap),
      valueMap
    )
  }
)(
  (_state, queryString, options = emptyObj) =>
    `${queryString || 'unknown'}|${Object.keys(options)
      .map(k => `${k}:${options[k]}`)
      .join('|')}`
)

export const selectLastAutoRedirectedTicketId = createBasicSelector(
  state => state.search?.lastAutoRedirectTicketId || null
)
